// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yQxudcIi_ffMdJZ_gi9w {\n    font-size: 14px;\n}\n\n.yQxudcIi_ffMdJZ_gi9w div {\n    padding: 10px 15px;\n}\n\n.yQxudcIi_ffMdJZ_gi9w h4 {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 1.1;\n    margin-bottom: 5px;\n}\n\n.yQxudcIi_ffMdJZ_gi9w p {\n    line-height: 1.3;\n    margin-bottom: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/web/collectors/CollectorsPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[":local(.sidecarModal) {\n    font-size: 14px;\n}\n\n:local(.sidecarModal) div {\n    padding: 10px 15px;\n}\n\n:local(.sidecarModal) h4 {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 1.1;\n    margin-bottom: 5px;\n}\n\n:local(.sidecarModal) p {\n    line-height: 1.3;\n    margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidecarModal": "yQxudcIi_ffMdJZ_gi9w"
};
export default ___CSS_LOADER_EXPORT___;
